import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';


function Login({ setSession }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const [msg, setMsg] = useState('');

  const apiPort = process.env.REACT_APP_API_PORT || 7001;

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/login' : window.location.protocol + '//' + window.location.hostname + '/api/login';
      const response = await axios.post(apiDir, { 'username': username, 'password': password });
      const { access_token, user } = response.data;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('user', JSON.stringify(user));
      // console.log('login: access_token', access_token);
      // console.log('login: user', user);
      setSession({ user: user, access_token: access_token });
      navigate('/');
    } catch (error) {
      console.error('Login failed', error);
      setMsg('Ingreso fallido. Por favor, revise usuario y contraseña.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Inicie Sesión</h2>
        <form onSubmit={handleLogin}>
          <div>
            <label>Usuario:</label>
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div>
            <label>Contraseña:</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <button type="submit">Login</button>
          {msg !== '' && 
          <div>
            <p>{msg}</p>
          </div>
          }
        </form>
      </div>
    </div>
  );
}

export default Login;
