import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CashierButton.css';


function CashierButton({ cashierData, onClick }) {

    function status2Str(status) {
        if (status === "OPEN") {
            return "ABIERTA";
        } else if (status === "PAUSED") {
            return "PAUSADA";
        } else if (status === "CLOSED") {
            return "CERRADA";
        }
    }

    function getClassName(status) {
        if (status === "OPEN") {
            return "card-open_status-open";
        } else if (status === "PAUSED") {
            return "card-open_status-paused";
        } else if (status === "CLOSED") {
            return "card-open_status-closed";
        }
    }

    return (
        <div className='cashier-button' onClick={onClick}>
            <div className="card">
                <div className="card-body">
                    <div className={getClassName(cashierData.open_status)}>{status2Str(cashierData.open_status)}</div>
                    <div className="separator"></div>
                    <div className="card-camera_id">{cashierData.camera_id}</div>
                </div>
            </div>
        </div>
    );
}

CashierButton.propTypes = {
    cashierData: PropTypes.shape({
        camera_id: PropTypes.string.isRequired,
        open_status: PropTypes.string.isRequired,
        calling: PropTypes.bool.isRequired,
        attended_clients: PropTypes.number.isRequired,
        mean_ta: PropTypes.number.isRequired
    }).isRequired,    onClick: PropTypes.func
};

CashierButton.defaultProps = {
    onClick: null // () => {} // Provide a default no-op function
};

export default CashierButton;