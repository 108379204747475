import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CashierBoxDisplay = ({ cashierData, foregroundColor, backgroundColor, onClick }) => {
    const containerStyle = {
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRadius: '10px',
        padding: '20px',
        maxWidth: '400px',
        margin: '20px auto',
        cursor: 'default' 
    };
    if (onClick !== null) {
        containerStyle.cursor = 'pointer'; // Add cursor pointer to indicate clickable area
    }

    const titleStyle = {
        fontSize: '1.2em',
        marginBottom: '10px'
    };

    const textStyle = {
        fontSize: '1.5em',
        textAlign: 'center',
        fontWeight: 'bold'
    };

    const lineStyle = {
        borderBottom: `1px solid ${foregroundColor}`,
        margin: '10px 0'
    };

    const [openStatus, setOpenStatus] = useState('');
    useEffect(() => {
        if (cashierData.open_status === 'CLOSED') {
            setOpenStatus('CERRADA');
        } else if (cashierData.open_status === 'PAUSED') {
            setOpenStatus('PAUSADA');
        } else if (cashierData.open_status === 'OPEN') {
            setOpenStatus('ABIERTA');
        }
        return () => {};
    }, [cashierData]);

    const [mean_ta, setMeanTa] = useState('');
    useEffect(() => {
        let hh = 0;
        let mm = 0;
        let ss = 0;
        let totalSeconds = cashierData.mean_ta;
        hh = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        mm = Math.floor(totalSeconds / 60);
        ss = Math.floor(totalSeconds % 60);
        if (hh < 10) {
            hh = `0${hh}`;
        }
        if (mm < 10) {
            mm = `0${mm}`;
        }
        if (ss < 10) {
            ss = `0${ss}`;
        }
        setMeanTa(`${mm}' ${ss}'' `);
        return () => {};
    }, [cashierData]);

    return (
        <div style={containerStyle} onClick={onClick}>
            <div style={titleStyle}>Caja {cashierData.camera_id}: {openStatus} {cashierData.calling === 1 && " - LLAMANDO"}</div>
            <div style={lineStyle}></div>
            <div style={textStyle}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6' style={{ textAlign: 'right' }}>Cl. Aten.:</div>
                        <div className='col-6' style={{ textAlign: 'right' }}>{cashierData.attended_clients !== null ? cashierData.attended_clients : 0}</div>
                    </div>
                    <div className='row'>
                        <div className='col-6' style={{ textAlign: 'right' }}>T.A. Prom.:</div>
                        <div className='col-6' style={{ textAlign: 'right' }}>{mean_ta}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CashierBoxDisplay.propTypes = {
    cashierData: PropTypes.shape({
        camera_id: PropTypes.string.isRequired,
        open_status: PropTypes.string.isRequired,
        calling: PropTypes.bool.isRequired,
        attended_clients: PropTypes.number.isRequired,
        mean_ta: PropTypes.number.isRequired
    }).isRequired,
    foregroundColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    onClick: PropTypes.func // Add onClick prop type
};

CashierBoxDisplay.defaultProps = {
    onClick: null // () => {} // Provide a default no-op function
};

export default CashierBoxDisplay;
