import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './ControlPanel.css';
import CashierButton from './CashierButton';
import TextBoxDisplay from './TextBoxDisplay';


function ControlPanel(session) {
    const [cashierList, setCashierData] = useState([]);
    const apiPort = process.env.REACT_APP_API_PORT || 7001;

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() +  '/api/cashier_status');
                const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/cashier_stats' : window.location.protocol + '//' + window.location.hostname + '/api/cashier_stats';
                const response = await axios.get(apiDir, {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "WWW-Authenticate": "Bearer",
                        "Authorization": `Bearer ${session.session.access_token}`
                    }
                });

                setCashierData(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        // Initial data fetch
        fetchData();

        // Set up interval for data refresh (every 500ms)
        const intervalId = setInterval(fetchData, 500);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [apiPort, session]);


    const changeState = (cashier) => {
        let new_status = 0;
        let new_status_str = "";
        if (cashier.open_status === "OPEN") {
            new_status = 3;
            new_status_str = "CERRADA";
        } else if (cashier.open_status === "CLOSED") {
            new_status = 1;
            new_status_str = "ABIERTA";
        }

        const fetchData = async () => {
            try {
                // await axios.put(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() + '/api/update_cashier_status', {
                const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/update_cashier_status' : window.location.protocol + '//' + window.location.hostname + '/api/update_cashier_status';
                await axios.put(apiDir, {
                    "cashier": cashier.camera_id,
                    "status": new_status
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'WWW-Authenticate': 'Bearer',
                        'Authorization': `Bearer ${session.session.access_token}`
                    }
                });

            } catch (error) {
                console.error("Error updating cashier status:", error);
            }
        };

        if (window.confirm("¿Está seguro que desea cambiar el estado de la CAJA " + cashier.camera_id + " a " + new_status_str + "?")) {
            fetchData();
        }
    }

    function checkIndex(index, cantCol) {
        if (index % cantCol === 0) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <div className='controlpanel-container' >
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className="col">
                            <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Panel de Control de las Cajas</h5>
                                <p className="card-text">En esta página usted puede controlar el estado de las cajas para que el llamador pueda enviar clientes a la misma.</p>
                            </div>
                            </div>
                        </div>

                    </div>
                    <div className='row justify-content-center' style={{marginTop: "20px"}}>
                        {cashierList.map((cashier, index) => (
                            <div className="col">
                                <CashierButton key={index} cashierData={cashier} onClick={() => changeState(cashier)} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ControlPanel;
