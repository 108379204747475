import React, { useState, useEffect, useRef } from 'react';
import './Caller.css';

// const sounds = [
//   "/bell-chord1-83260.mp3",
//   "/ding-36029.mp3",
//   "/doorbell-shortened-100308.mp3",
//   "/news-ting-6832.mp3",
// ];

const CallItemImportant = ({ name, showing }) => {
  // const randomNumber = Math.floor(Math.random() * sounds.length);
  // const soundRef = useRef(null);

  // useEffect(() => {
  //   if (soundRef.current) {
  //     soundRef.current.play();
  //   }
  // }, [name]);

  return (
    <div style={{
      minHeight: "50vh",
      minWidth: "90vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 50,
      background: "linear-gradient(to bottom, white, #30beca, #fbcd00)",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
      fontSize: "10vw",
      color: "white",
      animation: `fadeInOut ${showing || '6'}s ease-in-out`,
      position: "absolute",
      zIndex: "1",
    }}>
      CAJA
      <span style={{
        fontSize: "20vw",
        color: "white",
        margin: "0 100px",
      }}>{name}</span>
      LIBRE
      {/* <audio ref={soundRef} src={sounds[randomNumber]} preload="auto" /> */}
    </div>
  );
}

const Caller = (session) => {
  const [llamadas, setLlamadas] = useState([]);

  useEffect(() => {
    // const socket = new WebSocket('ws://0.0.0.0:18004')
    const wsDir = window.location.hostname === "localhost" ? "ws://localhost:18001" : "wss://" + window.location.hostname + "/caller-backend";
    const socket = new WebSocket(wsDir);

    socket.onmessage = (event) => {
      const nextCall = JSON.parse(event.data);

      if (Boolean(nextCall.calling)) {
        setLlamadas(prevArray => [nextCall, ...prevArray.filter(elemento => elemento.name !== nextCall.name)]);
      } else {
        setLlamadas(prevArray => prevArray.filter(elemento => elemento.name !== nextCall.name));
      }
    };

    return () => {
      socket.close();
    };
  }, [session]);

  return (
    <div style={{
      minHeight: "91vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(to bottom, black, #30beca, #fbcd00)",
      height: "100%",
    }}>
      {llamadas.length > 0 && <CallItemImportant name={llamadas[0]?.name} showing={llamadas[0]?.showing_time} />}
    </div>
  )
}

export default Caller;
