import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CashierDetailBoxDisplay = ({ camera_id, cashierReIdData, cashierOutputData, foregroundColor, backgroundColor }) => {
    const containerStyle = {
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRadius: '10px',
        padding: '20px',
        maxWidth: '1400px',
        margin: '20px auto'
    };

    const titleStyle = {
        fontSize: '1.2em',
        marginBottom: '10px'
    };

    const textStyle = {
        fontSize: '1.5em',
        textAlign: 'center',
        fontWeight: 'bold'
    };

    const lineStyle = {
        borderBottom: `1px solid ${foregroundColor}`,
        margin: '10px 0'
    };

    return (
        <div style={containerStyle}>
            <h2>Detalle Caja {camera_id}</h2>
            <div style={lineStyle}></div>

          <div className="row">
            <div>
              <h4>Tiempos de Atención</h4>
              <div style={{ minHeight: '200px', maxHeight: '200px', overflowY: 'auto' }}>
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Actualizado</th>
                      <th>Tiempo</th>
                      <th>ROI</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cashierReIdData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.object_id}</td>
                        <td>{row.last_timestamp}</td>
                        <td>{row.ta_sec}</td>
                        <td>{row.roi}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <h4>Datos Procesados</h4>
              <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Timestamp</th>
                      <th>Ids</th>
                      <th>Personas</th>
                      <th>Clientes</th>
                      <th>Atención</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cashierOutputData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.timestamp}</td>
                        <td>{row.ids}</td>
                        <td>{row.persons}</td>
                        <td>{row.clients}</td>
                        <td>{row.ta}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
    );
};

CashierDetailBoxDisplay.propTypes = {
    camera_id: PropTypes.string.isRequired,
    cashierReIdData: PropTypes.shape({
        object_id: PropTypes.string.isRequired,
        last_timestamp: PropTypes.string.isRequired,
        ta_sec: PropTypes.number.isRequired,
        roi: PropTypes.number.isRequired
    }).isRequired,
    cashierOutputData: PropTypes.shape({
        timestamp: PropTypes.string.isRequired,
        ids: PropTypes.string.isRequired,
        persons: PropTypes.number.isRequired,
        clients: PropTypes.number.isRequired,
        ta: PropTypes.number.isRequired
    }).isRequired,
    foregroundColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired
};

export default CashierDetailBoxDisplay;
