import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTachometerAlt, faCogs, faUsers, faSignOutAlt, faPhone, faKeyboard } from '@fortawesome/free-solid-svg-icons';  // faChevronRight, faChevronLeft
import './LateralMenu.css'; // Ensure this file exists and is properly linked

function LateralMenu({ session, logout }) {
  // const [optionSelected, setOptionSelected] = useState('home');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();

  // const toggleMenu = () => {
  //   setIsCollapsed(!isCollapsed);
  // };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className={`lateral-menu ${isCollapsed ? 'collapsed' : ''}`}>
      {/* <button onClick={toggleMenu}>
        <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft} />
      </button> */}
      <nav>
        <ul>
          <li>
            <Link to="/">
              <FontAwesomeIcon icon={faHome} />
              {!isCollapsed && <span>Inicio</span>}
            </Link>
          </li>
          {session.user.allow_dashboard === 1 && (
            <li>
              <Link to="/dashboard">
                <FontAwesomeIcon icon={faTachometerAlt} />
                {!isCollapsed && <span>Dashboard</span>}
              </Link>
            </li>
          )}
          {session.user.allow_controlpanel === 1 && (
            <li>
              <Link to="/control_panel">
                <FontAwesomeIcon icon={faKeyboard} />
                {!isCollapsed && <span>Botonera</span>}
              </Link>
            </li>
          )}
          {session.user.allow_caller === 1 && (
            <li>
              <Link to="/caller">
                <FontAwesomeIcon icon={faPhone} />
                {!isCollapsed && <span>Llamador</span>}
              </Link>
            </li>
          )}
          {session.user.allow_useradmin === 1 && (
            <li>
              <Link to="/users">
                <FontAwesomeIcon icon={faUsers} />
                {!isCollapsed && <span>Usuarios</span>}
              </Link>
            </li>
          )}
          {session.user.allow_settings === 1 && (
            <li>
              <Link to="/settings">
                <FontAwesomeIcon icon={faCogs} />
                {!isCollapsed && <span>Settings</span>}
              </Link>
            </li>
          )}
          <li>
              <button onClick={handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                {!isCollapsed && <span>Salir</span>}
              </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default LateralMenu;