import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEraser } from '@fortawesome/free-solid-svg-icons';  // faChevronRight, faChevronLeft
import './UserManagement.css';

function UserManagement({ session }) {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', password: '', salt: '', fullname: '', email: '', allow_dashboard: 0, allow_controlpanel: 0, allow_caller: 0, allow_useradmin: 0, allow_settings: 0, enabled: 1 });
  const [editUser, setEditUser] = useState(null);
  const apiPort = process.env.REACT_APP_API_PORT || 7001;

  // const fetchUsers = async () => {
  //   try {
  //     const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/users' : window.location.protocol + '//' + window.location.hostname + '/api/users';
  //     const response = await axios.get(apiDir, {
  //       headers: {
  //         'Authorization': `Bearer ${session.access_token}`
  //       }
  //     });
  //     setUsers(response.data);
  //   } catch (error) {
  //     console.error('Error fetching users:', error);
  //   }
  // };

  const fetchUsers = useCallback(() => {
    try {
      const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/users' : window.location.protocol + '//' + window.location.hostname + '/api/users';
      axios.get(apiDir, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`
        }
      }).then(response => { setUsers(response.data); });
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, [apiPort, session]);

  const handleAddUser = async () => {
    try {
      const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/users' : window.location.protocol + '//' + window.location.hostname + '/api/users';
      await axios.post(apiDir, newUser, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`
        }
      });
      setNewUser({ username: '', password: '', salt: '', fullname: '', email: '', allow_dashboard: 0, allow_controlpanel: 0, allow_caller: 0, allow_useradmin: 0, allow_settings: 0, enabled: 1 });
      fetchUsers();
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const handleEditUser = async () => {
    try {
      const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/users/' + editUser.id : window.location.protocol + '//' + window.location.hostname + '/api/users/' + editUser.id;
      await axios.put(apiDir, editUser, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`
        }
      });
      setEditUser(null);
      fetchUsers();
    } catch (error) {
      console.error('Error editing user:', error);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/users/' + id : window.location.protocol + '//' + window.location.hostname + '/api/users/' + id;
      await axios.delete(apiDir, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`
        }
      });
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleSetEditUser = async (user) => {
    setEditUser(user);
    setEditUser({ ...user, password: '', salt: '' });
  };


  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <div className="user-management-container">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Administración de Usuarios</h5>
                <p className="card-text">Aquí puede administrar los usuarios de la aplicación.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingTop: "20px" }}>
          <div className="col-md-8 card">
            <div className="user-list">
              <h4 style={{ paddingTop: "10px" }}>Usuarios Registrados</h4>

              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th></th>
                    <th>Id</th>
                    <th>Usuario</th>
                    <th>Nombre Completo</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(user => (
                    <tr key={user.id}>
                      <td>
                        <button onClick={() => handleSetEditUser(user)} title="Editar">
                          <FontAwesomeIcon icon={faEdit} />
                        </button> &nbsp;
                        <button onClick={() => handleDeleteUser(user.id)} title="Eliminar">
                          <FontAwesomeIcon icon={faEraser} />
                        </button>
                      </td>
                      <td>{user.id}</td>
                      <td>{user.username}</td>
                      <td>{user.fullname}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col card" style={{ marginLeft: "15px" }}>
            <div className="user-form">
              <h4 style={{ paddingTop: "10px" }}>{editUser ? 'Modificar Usuario (Id = ' + editUser.id.toString() + ')' : 'Agregar Usuario'}</h4>
              <input
                type="text"
                placeholder="Usuario"
                value={editUser ? editUser.username : newUser.username}
                readOnly={editUser ? true : false}
                onChange={(e) => editUser ? setEditUser({ ...editUser, username: e.target.value }) : setNewUser({ ...newUser, username: e.target.value })}
                title="Nombre de usuario"
              />
              <input
                type="password"
                placeholder="Contraseña"
                value={editUser ? editUser.password : newUser.password}
                onChange={(e) => editUser ? setEditUser({ ...editUser, password: e.target.value }) : setNewUser({ ...newUser, password: e.target.value })}
                title="Contraseña"
              />
              <input
                type="text"
                placeholder="Nombre Completo"
                value={editUser ? editUser.fullname : newUser.fullname}
                onChange={(e) => editUser ? setEditUser({ ...editUser, fullname: e.target.value }) : setNewUser({ ...newUser, fullname: e.target.value })}
                title="Nombre completo"
              />
              <input
                type="email"
                placeholder="Email"
                value={editUser ? editUser.email : newUser.email}
                onChange={(e) => editUser ? setEditUser({ ...editUser, email: e.target.value }) : setNewUser({ ...newUser, email: e.target.value })}
                title="Correo electrónico"
              />

              <h5>Permisos</h5>

              <div className='container'>
                <div className='row'>
                  <div className='col'>
                    <label title="Permitir acceso al Dashboard" for="chkDashboard" className="chk-label">Dashboard</label>
                  </div>
                  <div className='col'>
                    <input
                      id="chkDashboard"
                      type="checkbox"
                      className='chk-input'
                      checked={editUser ? editUser.allow_dashboard : newUser.allow_dashboard}
                      onChange={(e) => editUser ? setEditUser({ ...editUser, allow_dashboard: e.target.checked ? 1 : 0 }) : setNewUser({ ...newUser, allow_dashboard: e.target.checked ? 1 : 0 })}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <label title="Permitir acceso a la Botonera" for="chkControlPanel" className="chk-label">Botonera</label>
                  </div>
                  <div className='col'>
                    <input
                      id="chkControlPanel"
                      type="checkbox"
                      className='chk-input'
                      checked={editUser ? editUser.allow_controlpanel : newUser.allow_controlpanel}
                      onChange={(e) => editUser ? setEditUser({ ...editUser, allow_controlpanel: e.target.checked ? 1 : 0 }) : setNewUser({ ...newUser, allow_controlpanel: e.target.checked ? 1 : 0 })}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <label title="Permitir acceso al Llamador" for="chkCaller" className="chk-label" >Llamador</label>
                  </div>
                  <div className='col'>
                    <input
                      id='chkCaller'
                      type="checkbox"
                      className='chk-input'
                      checked={editUser ? editUser.allow_caller : newUser.allow_caller}
                      onChange={(e) => editUser ? setEditUser({ ...editUser, allow_caller: e.target.checked ? 1 : 0 }) : setNewUser({ ...newUser, allow_caller: e.target.checked ? 1 : 0 })}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <label title="Permitir acceso a la Administración de Usuarios" for="chkUserAdmin" className='chk-label' >ABM de Usuarios</label>
                  </div>
                  <div className='col'>
                    <input
                      id='chkUserAdmin'
                      type="checkbox"
                      className='chk-input'
                      checked={editUser ? editUser.allow_useradmin : newUser.allow_useradmin}
                      onChange={(e) => editUser ? setEditUser({ ...editUser, allow_useradmin: e.target.checked ? 1 : 0 }) : setNewUser({ ...newUser, allow_useradmin: e.target.checked ? 1 : 0 })}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <label title="Permitir acceso a la Configuración" for="chkSettings" className='chk-label'>Settings</label>
                  </div>
                  <div className='col'>
                    <input
                      id='chkSettings'
                      type="checkbox"
                      className='chk-input'
                      checked={editUser ? editUser.allow_settings : newUser.allow_settings}
                      onChange={(e) => editUser ? setEditUser({ ...editUser, allow_settings: e.target.checked ? 1 : 0 }) : setNewUser({ ...newUser, allow_settings: e.target.checked ? 1 : 0 })}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <label title="Usuario habilitado" for="chkEnabled" className='chk-label' >Habilitido</label>
                  </div>
                  <div className='col'>
                    <input
                      id='chkEnabled'
                      type="checkbox"
                      className='chk-input'
                      checked={editUser ? editUser.enabled : newUser.enabled}
                      onChange={(e) => editUser ? setEditUser({ ...editUser, enabled: e.target.checked ? 1 : 0 }) : setNewUser({ ...newUser, enabled: e.target.checked ? 1 : 0 })}
                    />
                  </div>
                </div>
              </div>
              <button onClick={editUser ? handleEditUser : handleAddUser}>
                {editUser ? 'Guardar Cambios' : 'Agregar Usuario'}
              </button>
              {editUser && <button onClick={() => setEditUser(null)}>Cancelar</button>}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default UserManagement;
