import React from 'react';
import PropTypes from 'prop-types';

const TextBoxDisplay = ({ title, text1, text2, foregroundColor, backgroundColor, onClick }) => {
    const containerStyle = {
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRadius: '10px',
        padding: '20px',
        maxWidth: '400px',
        margin: '20px auto',
        cursor: 'default' 
    };
    if (onClick !== null) {
        containerStyle.cursor = 'pointer'; // Add cursor pointer to indicate clickable area
    }

    const titleStyle = {
        fontSize: '1.2em',
        marginBottom: '10px'
    };

    const text1Style = {
        fontSize: '1.0em',
        textAlign: 'center',
    };

    const text2Style = {
        fontSize: '1.5em',
        textAlign: 'center',
        fontWeight: 'bold'
    };

    const lineStyle = {
        borderBottom: `1px solid ${foregroundColor}`,
        margin: '10px 0'
    };

    return (
        <div style={containerStyle} onClick={onClick}>
            <div style={titleStyle}>{title}</div>
            <div style={lineStyle}></div>
            <div style={text1Style}>{text1}</div>
            <div style={text2Style}>{text2}</div>
        </div>
    );
};

TextBoxDisplay.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    foregroundColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    onClick: PropTypes.func // Add onClick prop type
};

TextBoxDisplay.defaultProps = {
    onClick: null // () => {} // Provide a default no-op function
};

export default TextBoxDisplay;
