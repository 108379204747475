import React from 'react';
import ReactDOM from 'react-dom/client';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
// import Dashboard from './Dashboard';
import App from './App';
// import ControlPanel from './ControlPanel';
// import Caller from './Caller';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <Router>
//     <Routes>
//       <Route path="/" element={<Dashboard />} />
//       <Route path="/control_panel" element={<ControlPanel />} />
//       <Route path="/caller" element={<Caller />} />
//       <Route path="/health" element={<div>OK</div>} />
//     </Routes>
//   </Router>
// );

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
