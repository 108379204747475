import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Dashboard.css';
import TextBoxDisplay from './TextBoxDisplay';
import CashierBoxDisplay from './CashierBoxDisplay';
import CashierDetailBoxDisplay from './CashierDetailBoxDisplay';


function Dashboard(session) {
  const [cameraId, setCameraId] = useState(5);
  const [openCashiersStatus, setOpenCashiersStatus] = useState({ total_cashiers: 0, total_open_cashiers: 0, open_cashiers: [] });
  const [cashierStats, setCashierStats] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [outputData, setOutputData] = useState([]);

  const apiPort = process.env.REACT_APP_API_PORT || 7001;
  
  // Open Cashiers Status
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/open_cashiers' : window.location.protocol + '//' + window.location.hostname + '/api/open_cashiers';
        const response = await axios.get(apiDir, 
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": `Bearer ${session.session.access_token}`
            }
          }
        );
        setOpenCashiersStatus(response.data);
        console.log("Open Cashiers Status: ", response.data);
      } catch (error) {
        console.error("Error fetching data for open cashiers:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval for data refresh (every 500ms)
    const intervalId = setInterval(fetchData, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [apiPort, session]);


  // Cashiers Stats
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/cashier_stats' : window.location.protocol + '//' + window.location.hostname + '/api/cashier_stats';
        const response = await axios.get(apiDir,
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": `Bearer ${session.session.access_token}`
            }
          }
        );
        setCashierStats(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval for data refresh (every 500ms)
    const intervalId = setInterval(fetchData, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [apiPort, cameraId, session]);



  // Fetch the table data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() + '/api/data?camera_id=' + cameraId);
        // const response = await axios.get('http://web-api:7001/api/data?camera_id=' + cameraId);

        const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/data?camera_id=' + cameraId : window.location.protocol + '//' + window.location.hostname + '/api/data?camera_id=' + cameraId;
        const response = await axios.get(apiDir);
        setTableData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval for data refresh (every 500ms)
    const intervalId = setInterval(fetchData, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [apiPort, cameraId]);

  // Output Table
  useEffect(() => {
    const fetchData = async () => {
      try {
        // window.console.log("Fetching ouput data...");
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + ':' + apiPort.toString() + '/api/processed_data?camera_id=' + cameraId);
        // const response = await axios.get(window.location.protocol + '//' + window.location.hostname + '/api/processed_data?camera_id=' + cameraId);
        const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/processed_data?camera_id=' + cameraId : window.location.protocol + '//' + window.location.hostname + '/api/processed_data?camera_id=' + cameraId;
        const response = await axios.get(apiDir);
        setOutputData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval for data refresh (every 500ms)
    const intervalId = setInterval(fetchData, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [apiPort, cameraId]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
        <TextBoxDisplay title="Cajas Abiertas" text1={openCashiersStatus.open_cashiers.toString()} text2={openCashiersStatus.total_open_cashiers.toString() + ' de ' + openCashiersStatus.total_cashiers.toString()} foregroundColor="#04105b" backgroundColor="#ffffff" />
        {cashierStats.map((row, index) => (
            <CashierBoxDisplay
              key={row.camera_id}
              cashierData={row}
              foregroundColor={cameraId === row.camera_id ? "#ffffff" : '#04105b'}
              backgroundColor={cameraId === row.camera_id ? "#04105b" : "#ffffff"}
              onClick={() => setCameraId(row.camera_id)}
            />
          ))}
        </div>

        <div className="col-md-8">
          <CashierDetailBoxDisplay
            key={cameraId}
            camera_id={cameraId}
            cashierReIdData={tableData}
            cashierOutputData={outputData}
            foregroundColor="#04105b"
            backgroundColor="#ffffff"
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
