import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo_cf from './logo_cf.svg';
import logo_g2f from './logo_G2F.png';
import LateralMenu from './LateralMenu';
import Login from './Login';
import Home from './Home';
import Dashboard from './Dashboard';
import ControlPanel from './ControlPanel';
import Caller from './Caller';
import UserManagement from './UserManagement';
import Settings from './Settings';


function App() {
  const [session, setSession] = useState(null);

  const apiPort = process.env.REACT_APP_API_PORT || 7001;

  useEffect(() => {
    // localStorage.removeItem('access_token');
    // localStorage.removeItem('user');
    const access_token = localStorage.getItem('access_token');
    const user = JSON.parse(localStorage.getItem('user'));
    console.log('App: access_token', access_token);
    console.log('App: user', user);
    if (access_token) {
      const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/session' : window.location.protocol + '//' + window.location.hostname + '/api/session';
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      axios.get(apiDir)
        .then(response => {
          // console.log('Session validated', response.data);
          // setSession({ user: response.data.user, access_token });
          setSession({ user: user, access_token: access_token });
        })
        .catch(error => {
          console.error('Session validation failed', error);
          setSession(null);
          localStorage.removeItem('access_token');
          localStorage.removeItem('user');
        });
    }
  }, [apiPort]);

  const logout = () => {
    setSession(null);
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    delete axios.defaults.headers.common['Authorization'];
    // Optionally, you can also make an API call to invalidate the session on the server
    // axios.post('/api/logout').then(() => {
    //   setSession(null);
    // });
  };

  // const openCallerWindow = () => {
  //   window.open('/caller_window', '_blank', 'width=800,height=600');
  // };


  return (
    <Router>
      {/* Separate route for Caller in a new window */}
      {/* <Routes>
        <Route path="/caller" element={<Caller session={session} />} onEnter={() => window.open('/caller', '_blank')} />
      </Routes> */}
      <div className="app-container">
        <header className="app-header">
          <div className="row" style={{ height: "9vh" }}>
            <div className="col-md-2 d-flex align-items-center"><img src={logo_cf} alt="logo_cf" id="header-image-logocf" /></div>
            <div className="col-md-8 d-flex align-items-center"><h1 className="header-text">Gestión de Cajas - Vicente Lopez</h1></div>
            <div className="col-md-2 d-flex align-items-center"><img src={logo_g2f} alt="logo_g2f" id="header-image-logog2f" /></div>
          </div>
        </header>

        <main>
          {session ? (
            <>
              <div className="row" style={{ minHeight: "91vh" }}>
                <div className="col-md-1" style={{ backgroundColor: "#101010" }}>
                  <LateralMenu session={session} logout={logout} />
                </div>
                <div className="col-md-11">
                  <Routes>
                    <Route path="/" element={<Home session={session} />} />
                    <Route path="/dashboard" element={<Dashboard session={session} />} />
                    <Route path="/control_panel" element={<ControlPanel session={session} />} />
                    <Route path="/caller" element={<Caller session={session} />} onEnter={() => window.open('/caller', '_blank')} />
                    <Route path="/users" element={<UserManagement session={session} />} />
                    <Route path="/settings" element={<Settings session={session} />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </div>
              </div>
            </>
          ) : (
            <Routes>
              <Route path="/login" element={<Login setSession={setSession} />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}
        </main>
      </div>
    </Router>
  );
}

export default App;
