import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import './Settings.css';

function Settings({ session }) {
  const [settings, setSettings] = useState([]);
  const [editSetting, setEditSetting] = useState(null);
  const [newValue, setNewValue] = useState('');
  const apiPort = process.env.REACT_APP_API_PORT || 7001;

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = useCallback(async () => {
    try {
      const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/settings' : window.location.protocol + '//' + window.location.hostname + '/api/settings';
      const response = await axios.get(apiDir, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`
        }
      });
      setSettings(response.data);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  }, [apiPort, session.access_token]);

  const handleEditSetting = async () => {
    try {
      const apiDir = window.location.hostname === "localhost" ? window.location.protocol + '//localhost:' + apiPort.toString() + '/api/settings/' + editSetting.id : window.location.protocol + '//' + window.location.hostname + '/api/settings/' + editSetting.id;
      await axios.put(apiDir, { value: newValue }, {
        headers: {
          'Authorization': `Bearer ${session.access_token}`
        }
      });
      setEditSetting(null);
      setNewValue('');
      fetchSettings();
    } catch (error) {
      console.error('Error updating setting:', error);
    }
  };

  return (
    <div className="settings-container">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Configuración</h5>
                <p className="card-text">Aquí puede establecer el horario de apertura y cierre del supermercado en el cual el turnero opera, como así también opciones para el llamador.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingTop: "20px", marginBottom: "20px" }}>


          {settings.map(setting => (
            <div className="col" key={setting.id}>
              <div className="card" style={{ minHeight: "250px" }}>
                <div className="card-body">
                  <h5 className="card-title">{setting.label}</h5>
                  <p className="card-text">{setting.value}</p>
                  <p className="card-help">{setting.help}</p>
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary" onClick={() => { setEditSetting(setting); setNewValue(setting.value); }}>
                    Editar <FontAwesomeIcon icon={faEdit} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {editSetting && (
          <div className="edit-setting">
            <h5>Editar {editSetting.label}</h5>
            <input
              type="text"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
            />
            <button className="btn btn-primary" onClick={handleEditSetting}>Guardar Cambios</button>
            <button className="btn btn-secondary" onClick={() => setEditSetting(null)}>Cancelar</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Settings;