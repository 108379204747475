import React, { useState, useEffect } from 'react';
import './Home.css';

function Home(session) {
    const [fullname, setFullname] = useState(session.session.user.fullname);

  return (
    <div className='home-container'>
      <div className='roundbox'>
        <h1>Bienvenido {fullname}</h1>
      </div>
    </div>
  );
}

export default Home;
